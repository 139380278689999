import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Total views for a given time period for all types of content`}</h2>
    <p>{`Provides a total view count for both live and on demand content for a given time period.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://analytics-api.video.ibm.com/v1/total-views/summary
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 10 by default, max. 10 000)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`data`}</inlineCode>{` key. The elements of the array contain every data point wrapped in a `}<inlineCode parentName="p">{`series`}</inlineCode>{` object
matching the query parameters. Each object has the following attributes under the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` key:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dimension_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested dimension type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`point`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric value`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "attributes": {
        "dimension_type": "summary",
        "point": "2020-07-16T00:00:00+01:00",
        "value": 11
      },
      "type": "Series"
    }
  ],
  "pagination": {
    "count": 10,
    "first": {
      "href": "/total-views/summary?_page=1&_limit=1"
    },
    "last": {
      "href": "/total-views/summary?_page=10&_limit=1"
    },
    "next": {
      "href": "/total-views/summary?_page=2&_limit=1"
    },
    "prev": null,
    "self": {
      "href": "/total-views/summary?_page=1&_limit=1"
    }
  }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation of the parameters has failed; the response contains a detailed explanation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Total views for a given time period for all content within a defined dimension`}</h2>
    <p>{`Provides a total view count for both live and on demand content for a defined dimension. Dimensions can be based on geographic regions, devices used to watch or reported by time intervals like month or day.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://analytics-api.video.ibm.com/v1/total-views/{dimension}
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dimension`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values are `}<inlineCode parentName="td">{`month`}</inlineCode>{`, `}<inlineCode parentName="td">{`day`}</inlineCode>{`, `}<inlineCode parentName="td">{`hour`}</inlineCode>{`, `}<inlineCode parentName="td">{`device`}</inlineCode>{`, `}<inlineCode parentName="td">{`view-source`}</inlineCode>{`, `}<inlineCode parentName="td">{`country`}</inlineCode>{`, `}<inlineCode parentName="td">{`region`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 10 by default, max. 10 000)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`data`}</inlineCode>{` key. The elements of the array contain every data point wrapped in a `}<inlineCode parentName="p">{`series`}</inlineCode>{` object
matching the query parameters. Each object has the following attributes under the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` key:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dimension_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested dimension type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`point`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric value`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "attributes": {
        "dimension_type": "day",
        "point": "2020-07-16T00:00:00+01:00",
        "value": 11
      },
      "type": "Series"
    }
  ],
  "pagination": {
    "count": 10,
    "first": {
      "href": "/total-views/day?_page=1&_limit=1"
    },
    "last": {
      "href": "/total-views/day?_page=10&_limit=1"
    },
    "next": {
      "href": "/total-views/day?_page=2&_limit=1"
    },
    "prev": null,
    "self": {
      "href": "/total-views/day?_page=1&_limit=1"
    }
  }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation of the parameters has failed, the response contains a detailed explanation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Total views for a given time period and content type`}</h2>
    <pre><code parentName="pre" {...{}}>{`GET https://analytics-api.video.ibm.com/v1/total-views/{content_type}/summary
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the content, either `}<inlineCode parentName="td">{`live`}</inlineCode>{` or `}<inlineCode parentName="td">{`recorded`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 10 by default, max. 10 000)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`data`}</inlineCode>{` key. The elements of the array contain every data point wrapped in a `}<inlineCode parentName="p">{`series`}</inlineCode>{` object
matching the query parameters. Each object has the following attributes under the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` key:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dimension_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested dimension type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`point`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric value`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "attributes": {
        "dimension_type": "summary",
        "point": "2020-07-16T00:00:00+01:00",
        "value": 11
      },
      "type": "Series"
    }
  ],
  "pagination": {
    "count": 10,
    "first": {
      "href": "/total-views/live/summary?_page=1&_limit=1"
    },
    "last": {
      "href": "/total-views/live/summary?_page=10&_limit=1"
    },
    "next": {
      "href": "/total-views/live/summary?_page=2&_limit=1"
    },
    "prev": null,
    "self": {
      "href": "/total-views/live/summary?_page=1&_limit=1"
    }
  }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation of the parameters has failed, the response contains a detailed explanation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Total views for a given time period and content type bucketed by a defined dimension`}</h2>
    <pre><code parentName="pre" {...{}}>{`GET https://analytics-api.video.ibm.com/v1/total-views/{content_type}/{dimension}
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the content, either `}<inlineCode parentName="td">{`live`}</inlineCode>{` or `}<inlineCode parentName="td">{`recorded`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of comma separated IDs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dimension`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values are `}<inlineCode parentName="td">{`month`}</inlineCode>{`, `}<inlineCode parentName="td">{`day`}</inlineCode>{`, `}<inlineCode parentName="td">{`hour`}</inlineCode>{`, `}<inlineCode parentName="td">{`device`}</inlineCode>{`, `}<inlineCode parentName="td">{`view-source`}</inlineCode>{`, `}<inlineCode parentName="td">{`country`}</inlineCode>{`, `}<inlineCode parentName="td">{`region`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 10 by default, max. 10 000)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`data`}</inlineCode>{` key. The elements of the array contain every data point wrapped in a `}<inlineCode parentName="p">{`series`}</inlineCode>{` object
matching the query parameters. Each object has the following attributes under the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` key:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dimension_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested dimension type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`point`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric value`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "attributes": {
        "dimension_type": "day",
        "point": "2020-07-16T00:00:00+01:00",
        "value": 11
      },
      "type": "Series"
    }
  ],
  "pagination": {
    "count": 10,
    "first": {
      "href": "/total-views/live/day?_page=1&_limit=1"
    },
    "last": {
      "href": "/total-views/live/day?_page=10&_limit=1"
    },
    "next": {
      "href": "/total-views/live/day?_page=2&_limit=1"
    },
    "prev": null,
    "self": {
      "href": "/total-views/live/day?_page=1&_limit=1"
    }
  }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation of the parameters has failed, the response contains a detailed explanation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      